import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventTemplateAsset } from '@models/events/event-template-asset.model';
import { EventCategory } from '@models/event-categories/event-category.model';
import { EventTemplateAssetType } from '@models/events/event-template-asset-type.enum';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventTemplateService } from '@services/events/event-template.service';
import { EventCategoriesService } from '@services/event-categories.service';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { SharedService } from '@services/shared/shared.service';
import { Keyword } from '@models/shared/keyword.model';
import { Asset, getAssetFromURL } from '@models/shared/asset.model';
import { EventTemplateAssetSaveRequest } from '@models/events/dto/event-template-asset-save.request';
import { CustomDialogData } from '@models/overlay-dialogs/custom-dialog-data.model';
import { CustomDialogComponent } from '@modules/shared/dialogs/custom-dialog/custom-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModules } from '@utils/shared-modules';
import { FileUploaderComponent } from '@modules/shared/components/file-uploader/file-uploader.component';
import { KeywordsSelectComponent } from '@modules/shared/components/keywords-select/keywords-select.component';
import { CategorySelectComponent } from '@modules/shared/components/category-select/category-select.component';
import { ColorPickerComponent } from '@modules/shared/components/color-picker/color-picker.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-template-asset-overlay-page',
  standalone: true,
  imports: [
    CommonModule,
    FormsModules,
    MatCheckboxModule,
    TranslateModule,
    FileUploaderComponent,
    CategorySelectComponent,
    KeywordsSelectComponent,
    ColorPickerComponent,
  ],
  providers: [EventTemplateService, EventCategoriesService, SharedService],
  templateUrl: './template-asset-overlay-page.component.html',
  styleUrl: './template-asset-overlay-page.component.scss',
})
export class TemplateAssetOverlayPageComponent {
  isEdit: boolean = false;

  allCategories: EventCategory[] = [];
  categories: EventCategory[] = [];
  subcategories: EventCategory[] = [];
  templateAssetTypes = EventTemplateAssetType.getAllEventTemplateTypes();
  keywords: Keyword[] = [];

  templateAsset?: EventTemplateAsset;

  form: FormGroup;

  loading = false;

  constructor(
    private eventTemplateService: EventTemplateService,
    private eventCategoriesService: EventCategoriesService,
    private sharedService: SharedService,
    public dialogRef: MatDialogRef<TemplateAssetOverlayPageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialog: MatDialog,
  ) {
    this.sharedService
      .getKeywords()
      .subscribe((keywords) => (this.keywords = keywords));

    if (data) {
      this.isEdit = data.isEdit;
      this.templateAsset = data.asset;
    }

    let thumbnailAsset = undefined;
    if (
      this.templateAsset?.thumbnailUrl &&
      this.templateAsset?.thumbnailUrl !== ''
    ) {
      thumbnailAsset = getAssetFromURL(this.templateAsset?.thumbnailUrl);
    }

    let asset = undefined;
    if (this.templateAsset?.url && this.templateAsset?.url !== '') {
      asset = getAssetFromURL(this.templateAsset?.url);
    }

    let mobileAsset = undefined;
    if (this.templateAsset?.mobileUrl && this.templateAsset?.mobileUrl !== '') {
      mobileAsset = getAssetFromURL(this.templateAsset?.mobileUrl);
    }

    let h265Asset = undefined;
    if (this.templateAsset?.h265Url && this.templateAsset?.h265Url !== '') {
      h265Asset = getAssetFromURL(this.templateAsset?.h265Url);
    }

    let h265MobileAsset = undefined;
    if (
      this.templateAsset?.h265MobileUrl &&
      this.templateAsset?.h265MobileUrl !== ''
    ) {
      h265MobileAsset = getAssetFromURL(this.templateAsset?.h265MobileUrl);
    }

    this.form = this.fb.group({
      name: [this.templateAsset?.name || '', Validators.required],
      type: [this.templateAsset?.type || '', Validators.required],
      repeat: [this.templateAsset?.repeat || false],
      backgroundColor: [this.templateAsset?.backgroundColor || ''],
      color: [this.templateAsset?.color || ''],
      categories: [
        this.templateAsset?.getEventCategoriesIds() || [],
        Validators.required,
      ],
      subcategories: [this.templateAsset?.getEventSubcategoriesIds() || []],
      thumbnailAsset: [thumbnailAsset],
      asset: [asset, Validators.required],
      mobileAsset: [mobileAsset],
      h265Asset: [h265Asset],
      h265MobileAsset: [h265MobileAsset],

      keywords: [this.templateAsset?.keywordIds || []],
    });

    if (data?.allCategories !== undefined) {
      this.allCategories = data.allCategories;

      this.filterAndSortCategoriesAndSubcategories();
    } else {
      this.eventCategoriesService
        .getEventCategories()
        .subscribe((eventCategories) => {
          this.allCategories = eventCategories;

          this.filterAndSortCategoriesAndSubcategories();
        });
    }

    this.form.controls['categories'].valueChanges.subscribe((val) => {
      this.onCategoryChange(val);
    });
  }

  filterAndSortCategoriesAndSubcategories() {
    this.categories = this.allCategories
      ?.filter((item: EventCategory) => !item.parentCategoryId)
      .sort((a, b) => a.name.localeCompare(b.name));
    this.subcategories = this.allCategories
      ?.filter((item: EventCategory) => item.parentCategoryId)
      .sort((a, b) => a.name.localeCompare(b.name));

    this.onCategoryChange(this.templateAsset?.getEventCategoriesIds());
  }

  onCategoryChange(val?: number[]) {
    if (val !== undefined && val.length > 0) {
      this.subcategories = this.allCategories
        .filter(
          (item: EventCategory) =>
            item.parentCategoryId && val.includes(item.parentCategoryId),
        )
        .sort((a, b) => a.name.localeCompare(b.name));
      let selectedSubcategories: number[] = this.form.value.subcategories;

      if (selectedSubcategories) {
        selectedSubcategories = selectedSubcategories.filter(
          (selectedCategoryId) =>
            this.subcategories
              .map((category) => category.id)
              .includes(selectedCategoryId),
        );
        this.form.controls['subcategories'].patchValue(selectedSubcategories);
      }
    } else {
      this.subcategories = this.allCategories
        .filter((item: EventCategory) => item.parentCategoryId)
        .sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  createOrUpdateAsset() {
    if (this.isFormValid()) {
      const value = this.form.value;

      const categoryIds = [
        ...new Set([...value.categories, ...value.subcategories]),
      ];

      const saveTemplateAssetReq: EventTemplateAssetSaveRequest = {
        name: value.name,
        type: value.type,

        thumbnailAsset: value.thumbnailAsset,
        asset: value.asset,
        mobileAsset: value.mobileAsset,

        h265Asset: value.h265Asset,
        h265MobileAsset: value.h265MobileAsset,

        repeat: value.repeat,

        backgroundColor: value.backgroundColor,
        color: value.color,

        categoryIds: categoryIds,
        keywordIds: value.keywords,
      };

      if (this.isEdit) {
        saveTemplateAssetReq.id = this.templateAsset?.id;
      }

      this.loading = true;

      this.eventTemplateService
        .saveAsset(saveTemplateAssetReq)
        .pipe(
          finalize(() => {
            this.loading = false;
          }),
        )
        .subscribe((templateAsset) => {
          const confirmation =
            'APP.AFFILIATE_PRODUCTS_PAGE_LIST.CUSTOM_DIALOG.PRODUCT_CONFIRMATION_MESSAGE';
          const okButtonMessage =
            'APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTION_BUTTONS.OK';
          const title =
            'APP.AFFILIATE_PRODUCTS_PAGE_LIST.CUSTOM_DIALOG.CONFIRMATION_TITLE';

          const data: CustomDialogData = {
            confirmation: confirmation,
            title: title,
            cancelButtonMessage: okButtonMessage,
            id: templateAsset.id,
            pictureUrl: templateAsset.thumbnailUrl,
          };

          this.dialog.open(CustomDialogComponent, {
            maxWidth: '455px',
            width: '100%',
            height: 'auto',
            data: data,
          });

          this.dialogRef.close({
            templateAsset: templateAsset,
            refresh: true,
          });
        });
    }
  }

  isFormValid(): boolean {
    this.form.markAllAsTouched();

    return this.form.valid;
  }

  getDisplayType(type: EventTemplateAssetType) {
    return EventTemplateAssetType.getDisplayName(type);
  }

  onAssetChanged(
    asset: Asset,
    assetName:
      | 'thumbnailAsset'
      | 'asset'
      | 'mobileAsset'
      | 'h265Asset'
      | 'h265MobileAsset',
  ) {
    this.form.controls[`${assetName}`].patchValue(asset);
  }
}
