<div class="overlay-page overlay-theme">
  <div class="header">
    <div class="flex-left-placeholder"></div>
    <h1 mat-dialog-title class="title" *ngIf="!isEdit">
      {{ "APP.TEMPLATE_ASSETS.SAVE.CREATE_TITLE" | translate }}
    </h1>
    <h1 mat-dialog-title class="title" *ngIf="isEdit">
      {{ "APP.TEMPLATE_ASSETS.SAVE.EDIT_TITLE" | translate }}
    </h1>
    <i class="fal fa-times-circle" (click)="cancel()"></i>
  </div>

  <div class="body-info">
    <form [formGroup]="form">
      <div>
        <h2 class="body-header">
          {{
            "APP.AFFILIATE_PRODUCTS_PAGE_LIST.CREATE_OR_EDIT_PRODUCT.BASIC_INFORMATION.TITLE"
              | translate
          }}*
        </h2>
        <div class="flex-inputs-cont basic-info">
          <mat-form-field
            class="flex-half-shrink flex-input"
            appearance="outline"
          >
            <mat-label class="placeholder">{{
              "APP.TEMPLATE_ASSETS.SAVE.NAME" | translate
            }}</mat-label>
            <input type="text" matInput formControlName="name" placeholder="" />
            @if (form.controls["name"].hasError("required")) {
              <mat-error>
                {{ "APP.TEMPLATE_ASSETS.ERRORS.NAME_REQUIRED" | translate }}
              </mat-error>
            }
          </mat-form-field>

          <mat-form-field
            class="flex-half-shrink flex-input"
            appearance="outline"
          >
            <mat-label class="placeholder">{{
              "APP.TEMPLATE_ASSETS.SAVE.TYPE" | translate
            }}</mat-label>
            <mat-select formControlName="type">
              <mat-option
                *ngFor="let option of templateAssetTypes"
                [value]="option"
              >
                {{ getDisplayType(option) | translate }}
              </mat-option>
            </mat-select>
            @if (form.controls["type"].hasError("required")) {
              <mat-error>
                {{ "APP.TEMPLATE_ASSETS.ERRORS.TYPE_REQUIRED" | translate }}
              </mat-error>
            }
          </mat-form-field>
        </div>

        @if (form.controls["type"].value === "BACKGROUND") {
          <div class="flex-inputs-cont basic-info">
            <app-color-picker
              [form]="form"
              colorFormControlName="backgroundColor"
              label="APP.TEMPLATE_ASSETS.SAVE.BACKGROUND_COLOR"
              class="flex-half-shrink flex-input"
            ></app-color-picker>
            <app-color-picker
              [form]="form"
              colorFormControlName="color"
              label="APP.TEMPLATE_ASSETS.SAVE.TEXT_COLOR"
              class="flex-half-shrink flex-input"
            ></app-color-picker>
          </div>
        } @else if (form.controls["type"].value === "EFFECT") {
          <mat-checkbox formControlName="repeat" class="mb-20"
            >{{ "APP.TEMPLATE_ASSETS.SAVE.REPEAT_VIDEO" | translate }}
          </mat-checkbox>
        }
      </div>

      <h2 class="body-header">
        {{ "APP.TEMPLATE_ASSETS.SAVE.THUMBNAIL" | translate }}
      </h2>
      <div class="file-container">
        <app-file-uploader
          [aspectRatio]="1 / 1"
          [fluidWidth]="true"
          [useImagePreviewComponent]="true"
          [uploaderType]="'IMAGE'"
          [selectedFile]="form.controls['thumbnailAsset'].value"
          [invalid]="
            form.controls['thumbnailAsset'].touched &&
            form.controls['thumbnailAsset'].invalid
          "
          (fileChanged)="onAssetChanged($event, 'thumbnailAsset')"
        >
        </app-file-uploader>
      </div>

      <h2 class="body-header">
        {{ "APP.TEMPLATE_ASSETS.SAVE.MAIN" | translate }}*
      </h2>
      <div class="file-container">
        <app-file-uploader
          [fluidWidth]="true"
          [useImagePreviewComponent]="true"
          [uploaderType]="'IMAGE_AND_VIDEO'"
          [selectedFile]="form.controls['asset'].value"
          [invalid]="
            form.controls['asset'].touched && form.controls['asset'].invalid
          "
          (fileChanged)="onAssetChanged($event, 'asset')"
        >
        </app-file-uploader>
      </div>

      @if (["BACKGROUND", "EFFECT"].includes(form.controls["type"].value)) {
        <h3 class="body-subheader">
          {{ "APP.TEMPLATE_ASSETS.SAVE.H265_VIDEO" | translate }}*
        </h3>
        <div class="file-container">
          <app-file-uploader
            [fluidWidth]="true"
            [useImagePreviewComponent]="true"
            [uploaderType]="'VIDEO'"
            [selectedFile]="form.controls['h265Asset'].value"
            [invalid]="
              form.controls['h265Asset'].touched &&
              form.controls['h265Asset'].invalid
            "
            (fileChanged)="onAssetChanged($event, 'h265Asset')"
          >
          </app-file-uploader>
        </div>
      }

      <h2 class="body-header">
        {{ "APP.TEMPLATE_ASSETS.SAVE.MOBILE" | translate }}
      </h2>
      <div class="file-container">
        <app-file-uploader
          [fluidWidth]="true"
          [useImagePreviewComponent]="true"
          [uploaderType]="'IMAGE_AND_VIDEO'"
          [selectedFile]="form.controls['mobileAsset'].value"
          [invalid]="
            form.controls['mobileAsset'].touched &&
            form.controls['mobileAsset'].invalid
          "
          (fileChanged)="onAssetChanged($event, 'mobileAsset')"
        >
        </app-file-uploader>
      </div>

      @if (["BACKGROUND", "EFFECT"].includes(form.controls["type"].value)) {
        <h3 class="body-subheader">
          {{ "APP.TEMPLATE_ASSETS.SAVE.H265_VIDEO" | translate }}*
        </h3>
        <div class="file-container">
          <app-file-uploader
            [fluidWidth]="true"
            [useImagePreviewComponent]="true"
            [uploaderType]="'VIDEO'"
            [selectedFile]="form.controls['h265MobileAsset'].value"
            [invalid]="
              form.controls['h265MobileAsset'].touched &&
              form.controls['h265MobileAsset'].invalid
            "
            (fileChanged)="onAssetChanged($event, 'h265MobileAsset')"
          >
          </app-file-uploader>
        </div>
      }

      <h2 class="body-header">
        {{
          "APP.AFFILIATE_PRODUCTS_PAGE_LIST.CREATE_OR_EDIT_PRODUCT.CHOOSE_CATEGORY"
            | translate
        }}*
      </h2>
      <app-category-select
        [form]="form"
        selectedFormControlName="categories"
        [eventCategories]="categories"
        requiredError="APP.AUTH.ERRORS.PRODUCT_CATEGORY_REQUIRED"
      ></app-category-select>

      <h2 class="body-header">
        {{
          "APP.AFFILIATE_PRODUCTS_PAGE_LIST.CREATE_OR_EDIT_PRODUCT.CHOOSE_SUBCATEGORY"
            | translate
        }}
      </h2>
      <app-category-select
        [form]="form"
        selectedFormControlName="subcategories"
        [eventCategories]="subcategories"
        requiredError="APP.AUTH.ERRORS.PRODUCT_SUBCATEGORY_REQUIRED"
      ></app-category-select>

      <h2 class="body-header">{{ "APP.ASSIGN_KEYWORDS" | translate }}*</h2>
      <app-keywords-select
        [form]="form"
        selectedFormControlName="keywords"
        [items]="keywords"
      ></app-keywords-select>
    </form>
  </div>

  <div class="floating-button-container">
    <button
      *ngIf="!isEdit"
      (click)="createOrUpdateAsset()"
      type="button"
      mat-raised-button
      class="actionButton btn btn-primary addButton"
    >
      {{ "APP.TEMPLATE_ASSETS.SAVE.CREATE_ACTION" | translate }}
    </button>

    <button
      *ngIf="isEdit"
      (click)="createOrUpdateAsset()"
      type="button"
      mat-raised-button
      class="actionButton btn btn-primary editButton"
    >
      {{ "APP.TEMPLATE_ASSETS.SAVE.UPDATE_ACTION" | translate }}
    </button>
  </div>

  @if (loading) {
    <div class="full-page-loader-cont">
      <div class="loader"></div>
    </div>
  }
</div>
